<template>
        <v-row no-gutters>
            <v-col cols="12" sm="11" md="11" lg="11">
                <div style="width: 100%; height: auto; display: flex;">
                    <v-select class="denseSelect" clearable v-model="filterItem.field"  hide-details :items="headers" item-text="text" return-object @change="changeFilter" dense outlined :menu-props="{'offset-y': true}"></v-select>
                    <v-select class="denseSelect ml-1" style="max-width: 75px;" v-model="filterItem.operator" @change="updateFilterItem" hide-details :items="['Is', 'Is Not']" dense outlined :menu-props="{'offset-y': true}"></v-select>
                </div>
                <div style="width: 100%; height: auto; display: flex;" v-if="filterItem.field" class="mt-1">
                    <v-select v-if="filterItem?.type == 'select'" class="denseSelect" @change="updateFilterItem" v-model="filterItem.value" hide-details :items="filterItem.items" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-select v-if="filterItem?.type == 'features'" class="denseSelect" v-model="filterItem.value" @change="updateFilterItem" hide-details :items="productTypes" item-text="product" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-select v-if="filterItem?.type == 'features' && filterItem.value && productTypes.find(x => x.product == filterItem.value).variations " @change="updateFilterItem" class="denseSelect ml-1" v-model="filterItem.selectedVariation" hide-details :items="productTypes.find(x => x.product == filterItem.value).variations" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-select v-if="filterItem?.type == 'date'" @change="updateFilterItem" class="denseSelect" v-model="filterItem.value" hide-details :items="dateTypes" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-select v-if="filterItem?.field == 'shippingLineName'" :loading="loadingContainers" @change="updateFilterItem" class="denseSelect" v-model="filterItem.value" hide-details :items="shippingLines" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-select v-if="filterItem?.field == 'vesselVoyage'" :loading="loadingContainers" @change="updateFilterItem" class="denseSelect" v-model="filterItem.value" hide-details :items="voyages" dense outlined :menu-props="{'offset-y': true}">  </v-select>
                    <v-text-field v-if="filterItem?.type == 'text'" @change="updateFilterItem" class="denseSelect" v-model="filterItem.value" hide-details  dense outlined >  </v-text-field>
                    <!-- <v-text-field v-if="filterItem?.type == 'date' && filterItem.value && ['Next', 'Last'].includes(filterItem.value)" @change="updateFilterItem" hide-details dense class="denseSelect textField ml-1" v-model="filterItem.dateText" outlined ></v-text-field> -->
                    <!-- <v-select v-if="filterItem?.type == 'date' && filterItem.value && ['Next', 'Last'].includes(filterItem.value)" @change="updateFilterItem" class="denseSelect ml-1" v-model="filterItem.dateRangeTypeSelected" hide-details :items="dateRangeTypes" dense outlined :menu-props="{'offset-y': true}">  </v-select> -->
                    <v-autocomplete v-if="filterItem?.type == 'country' || filterItem?.type.includes('country')" @change="updateFilterItem" hide-details hide-selected class="denseSelect autoComp" dense outlined :items="countries" item-text="name" item-value="name" :menu-props="{'offset-y': true, closeOnContentClick: true }" return-object v-model="filterItem.selectedCountry" :loading="loadingCountries">
                        <template v-slot:selection="data">
                            <v-avatar size="20" class="mr-1" v-if="data.item">
                                <v-img contain v-if="data.item.iso2" :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            <span v-if="data.item">
                                {{ data.item.name }}
                            </span>
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-avatar style="height: 20px; margin: 0;">
                                <v-img contain v-if="data.item.iso2" :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="filterItem?.type == 'countryPort'" auto-select-first :search-input.sync="autoSelectCountry" placeholder="Optional" @change="updateFilterItem" hide-details hide-selected class=" ml-1 denseSelect autoCompPlace" dense outlined :items="selectedPorts" item-text="locode" item-value="locode" :menu-props="{'offset-y': true, closeOnContentClick: true }" v-model="filterItem.value" :loading="loadingPorts">
                        <template v-slot:item="data">
                            <v-list-item-avatar style="height: 20px; margin: 0;">
                                <v-img contain v-if="data.item.countryCode" :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.locode"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="filterItem?.type == 'organisation'" @change="updateFilterItem" hide-details hide-selected class="denseSelect autoComp" dense outlined :items="related" item-text="name" item-value="name" :menu-props="{'offset-y': true, closeOnContentClick: true }" v-model="filterItem.value" :loading="loadingRelated">
                        <template v-slot:selection="data">
                            <div class="d-flex align-center" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 285px;">
                                <v-avatar size="24" :color="data.item && (data.item.logo || data.item.icon) ? 'white': 'secondary'" left>
                                  <v-img v-if="data.item.logo" :src="data.item.logo" contain></v-img>
                                  <v-img v-else-if="data.item.icon" :src="data.item.icon" contain></v-img>
                                  <h3 v-else style="color: white">
                                    {{ data.item.name.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                <span class="ml-2" style="font-size: 11px; text-transform: capitalize !important; text-wrap: nowrap; text-overflow: ellipsis; max-height: 30px;">{{ data.item.alias ?? data.item.aliasAlt ?? data.item.name ? $Format.removePtyLtd(data.item.name) : '' }}</span>
                            </div>
                        </template>
                        <template v-slot:item="data">
                            <v-avatar size="24" :color="data.item && data.item.logo? 'white': 'secondary'" left>
                              <v-img v-if="data.item.logo" :src="data.item.logo" contain></v-img>
                              <h3 v-else style="color: white">
                                {{ data.item.name.charAt(0) }}
                              </h3>
                            </v-avatar>
                            <span class="ml-2" style="font-size: 11px; text-transform: capitalize !important; text-wrap: nowrap; text-overflow: ellipsis;">{{ data.item.alias ?? data.item.aliasAlt ?? data.item.name ? $Format.removePtyLtd(data.item.name) : '' }}</span>
                        </template>
                    </v-autocomplete>
                </div>
            </v-col>
            <v-col cols="12" sm="1" md="1" lg="1" class="d-flex justify-center align-center">
                <v-btn icon color="redPop" plain small @click="$emit('remove')"><v-icon>close</v-icon></v-btn>
            </v-col>
        </v-row>
</template>

<script>
export default  {
    props: ['filter', 'voyages', 'shippingLines', 'loadingContainers'],
    data: () => ({
        filterItem: {},
        loadingCountries: false,
        related: [],
        loadingRelated: false,
        countries: [],
        ports: [],
        autoSelectCountry: '',
        selectedPorts: [],
        loadingPorts: false,
        dateTypes:[
            'Today',
            'Tomorrow',
            'Yesterday',
            'This Week',
            'Next Week',
            'Last Week',
            'This Month',
            'Next Month',
            'Last Month',
            'Next 7 days',
            'Next 30 days',
            'Next 60 days',
            'Next 90 days',
            'Last 7 days',
            'Last 30 days',
            'Last 60 days',
            'Last 90 days',
            'This Year',
            'Last Year',
            'No Date'
        ],
        dateRangeTypes: [
            'Days',
            'Weeks',
            'Months'
        ],
        productTypes: [
          {
            product: "Port Monitoring",
          },
          {
            product: "Genset",
          },
          {
            product: "Late Arrival",
            variations: ["Any","Standard","Rejected","Approved","Pending","Approved (No Charge)","Approved (Not Used)","Not Applicable",]
          },
          {
            product: "TelemPlus",
            variations: ["Any","2G GEO","3G GEO","RF","4G GEO","GEO EAGLE","2G TIVE","T4","USB","4G PROBE","STD PROBE","Escavox"]
          },
          {
            product: "Dunnage Bags",
          },
          {
            product: "Otflow",
          },
          {
            product: "Zoono",
          },
          {
            product: "Fumigation",
          },
          {
            product: "Void Plugs",
          },
          {
            product: "Blast Freezing",
          },
          {
            product: "Monitoring Service",
            variations:["Any","TempCheck","Port Monitoring","TempCheckPLUS","N/A","iCheck"]
          },
          {
            product: "Kraft paper",
          },
          {
            product: "Latches",
          },
          {
            product: "SteriInsurance",
          },
          {
            product: "Stabling bars",
          },
          {
            product: "Absorb Bags",
          },
          {
            product: "SmartFresh",
          },
          {
            product: "ContainerLiners",
          },
          {
            product: "Early Arrival",
            variations:["Any","Standard","Approved","Pending","Approved (No Charge)","Not Applicable","Rejected","Approved (Not Used)"]
          }
        ],
        headers: [
            {
                text: "Week ETD",
                value: "etdWeekYear",
                type: 'date',
            },
            {
                text: "Booking Status",
                value: "state",
                type: 'select',
                items:['Confirmed','Cancelled','Preliminary','Pending','Draft','Requested','Rejected','Futile']
            },
            {
                text: "Order Status",
                value: "orderStatus",
                type: 'select',
                items: ['Cancelled', 'Confirmed', 'In Progress', 'Planned']
            },
            {
                text: "OrderNo",
                value: "orderNo",
                type: 'text'
            },
            {
                text: "Allocated",
                value: "stockAllocationStatus",
                items: ['Full', 'Partial', 'None', 'Issue'],
                type: 'select'
            },
            {
                text: "LL No",
                value: "ctoNo",
                type: 'text'
            },
            {
                text: "SType",
                value: "serviceType",
                type: 'select',
                items: ['BREAKBULK', 'FCL', 'LCL']
            },
            {
                text: "Container No",
                value: "containerNo",
                type: "text"
            },
            {
                text: "Features",
                value: "features",
                type: 'features',
            },
            {
                text: "Products",
                value: "product",
                type: 'text'
            },
            {
                text: "Shipper",
                value: "shipperName",
                type: "organisation"
            },
            {
                text: "ShipperRef",
                value: "shipperReferenceNo",                
                type: 'text'
            },
            {
                text: "Buyer",
                value: "buyerName",                
                type: "organisation"
            },
            {
                text: "Consignee",
                value: "consigneeName",                
                type: "organisation"
            },
            {
                text: "Consignee Ref",
                value: "consigneeReferenceNo",                
                type: 'text'
            },
            {
                text: "Forwarder",
                value: "forwarderName",                
                type: "organisation"
            },
            {
                text: "Forwarder Ref",
                value: "fileNumber",                
                type: 'text'
            },
            {
                text: "Carrier Ref",
                value: "carrierReferenceNumber",                
                type: 'text'
            },
            {
                text: "On Behalf Shipper",
                value: "onBehalfShipperName",                
                type: "organisation"
            },
            {
                text: "On Behalf Forwarder",
                value: "onBehalfForwarderName",                
                type: "organisation"
            },
            {
                text: "On Behalf Buyer",
                value: "onBehalfBuyerName",                
                type: "organisation"
            },
            {
                text: "On Behalf Consignee",
                value: "onBehalfConsigneeName",                
                type: "organisation"
            },
            {
                text: "Docs",
                value: "fileStatus",
                exclusive: ['forwarder', 'shipper', 'onBehalfShipper', 'onBehalfForwarder'],
                type: 'select',
                items: ['Not Ready', 'Ready to Send', 'Sent', 'Not Set']
            },
            {
                text: "Carrier",
                value: "shippingLineName",
                type: "computed"
            },
            {
                text: "Vessel",
                value: "vesselVoyage",
                type: 'computed'
            },
            {
                text: "Origin Country",
                value: "originCountryName",                
                type: 'country'
            },
            {
                text: "POL",
                value: "portOfLoadValue",                
                type: 'countryPort'
            },
            {
                text: "POD",
                value: "portOfDischargeValue",                
                type: 'countryPort'
            },
            {
                text: "Final Dest",
                value: "finalDestinationValue",
                type: 'countryPort'
            },
            {
                text: "Week-ETA",
                value: "etaWeekYear",
                type: 'date',
            },
            {
                text: "ETD",
                value: "etd",
                type: 'date',
            },
            {
                text: "ETA",
                value: "eta",
                type: 'date',
            },
            {
                text: "Total Pallets",
                value: "totalPallets",
                type: 'text'
            },
            {
                text: "Total Units ",
                value: "totalPackageCount",
                type: 'text'
            },
            {
                text: "INCO (FF)",
                value: "incoTerm",                
                exclusive: ['forwarder'],
                exclusiveToOrg: [2507],
                type: "select",
                items: ["EXW","DAP","FOB","CIF","CFR","DPU","FCA","DDP","CIP","FAS"]
            },
            {
                text: "Deal Term",
                value: "financialType",
                type: 'select',
                items: ["FIXED", "MGP", "FULL CONSIGNMENT"]
            },
            {
                text: "INCO",
                value: "financialIncoTerm",
                type: 'select',
                items: ["EXW","DAP","FOB","CIF","CFR","DPU","FCA","DDP","CIP","FAS"]
            },
            {
                text: "Freight Term",
                value: "freightTerm",
                type: 'select',
                items: ["Prepaid","Collect"]
            },
            
        ],
    }),
    created() {
        if (this.filter) {
            this.filterItem = JSON.parse(JSON.stringify(this.filter));
        }
    },
    watch: {
        'filterItem.type': {
            deep: true,
            immediate: true,
            handler: function (val) {
                if ((val == 'country' || (val && val.includes('country'))) && this.countries.length == 0) {
                    this.getCountries();
                }
                if (val == 'organisation' && this.related.length == 0) {
                    this.getRelatedOrganisations();
                }
                if(val == 'computed') {
                    if(this.filterItem.field == 'vesselVoyage') {
                        this.$emit('getVoyages')
                    } else if(this.filterItem.field == 'shippingLines') {
                        this.$emit('getShippingLines')
                    }
                }
            },
        },
        'filterItem.selectedCountry' : {
            deep: true,
            immediate: true,
            handler: function(val) {
                if(this.filterItem.type == 'countryPort' && val) {
                    this.getPortByCountry(val)
                }
            }
        },
        async autoSelectCountry(val) {
            console.log(val)
            if(this.filterItem && this.filterItem.type == 'countryPort' && val) {
                if(val.length > 1) {
                    let result = this.countries.find(x => x.iso2 && x.iso2.substring(0, 2) == val.substring(0, 2));
                    if(result) {
                        this.filterItem.selectedCountry = result;
                        this.getPortByCountry(result)
                    }
                }
            }
        },
        'filterItem': {
            immediate: true,
            handler(newVal, oldVal) {
                if((JSON.stringify(oldVal) != '{}' && JSON.stringify(oldVal) != undefined) && newVal) {
                    console.log('NEWVAL:',newVal,'OLDVAL:', JSON.stringify(oldVal))
                    this.$emit('changed', JSON.stringify(this.filterItem))
                }
            }
        }
    },
    methods: {
        async getPortByCountry(val) {
            console.log(val)
            this.loadingPorts = true;
            if(this.ports.length > 0) {
                let find = this.ports.find(x => x.country == val.iso2) || null;
                if(find) {
                    this.selectedPorts = find.ports;
                    this.loadingPorts = false;
                    return
                }
            }
            let result = await this.$API.getPortsByCountry(val.iso2);
            this.ports.push({country: val.iso2, ports: result})
            this.selectedPorts = result;
            this.loadingPorts = false;
            console.log(this.ports)
        },
        async getRelatedOrganisations() {
            let result = await this.$API.getRelationBasic({});
            if(result && result.data?.length > 0) {
                this.related = result.data.map(x => {
                    return {
                    id: x.relatedOrganisation.id,
                        alias: x.alias,
                        logo: x.relatedOrganisation.logo,
                        icon: x.relatedOrganisation.icon,
                        name: x.relatedOrganisation.name,
                        aliasAlt: x.relatedOrganisation.alias,
                    }
                })
            }
        },

        updateFilterItem() {
            this.filterItem = JSON.parse(JSON.stringify(this.filterItem))
        },
        async getCountries() {
            this.loadingCountries = true;
            this.countries = await this.$API.searchCountriesBasic({});
            console.log(this.countries)
            this.loadingCountries = false;
        },
        changeFilter(item) {
            this.filterItem = {};
            this.filterItem.value = '';
            this.selectedVariation = 'Any';
            if (item) {
            this.filterItem = {
                field: item.value,
                value: '',
                type: item.type,
                items: item.items,
            };
            if(item.type == 'country') {
                this.filterItem.selectedCountry = null
            }
            if(item.type == 'features') {
                this.filterItem.selectedVariation = 'Any'
            }
        }
        this.updateFilterItem();
        }
    },
}
</script>

<style scoped>
.denseSelect {
    max-height: 30px;
}
.denseSelect ::v-deep div.v-input__control div.v-input__slot {
    font-size: 12px !important;
    max-height: 30px !important;
    min-height: auto !important;
    display: flex !important;
    padding-right: 0px !important;
    padding-left: 8px !important;
    align-items: center !important;
}

.denseSelect.textField ::v-deep div.v-input__control div.v-input__slot {
    width: 40px !important;
}

.autoCompPlace {
    max-width: 150px !important;
}
.autoCompPlace ::v-deep div.v-input__control div.v-input__slot {
    font-size: 12px !important;
    max-height: 30px !important;
    min-height: auto !important;
    display: flex !important;
    padding-right: 0px !important;
    padding-left: 8px !important;
    align-items: center !important;
}

.denseSelect.autoCompPlace ::v-deep .v-input__append-inner {
    margin-top: none !important;
}


.autoComp {
    max-width: 328px !important;
}
.autoComp ::v-deep input {
    width: 1px !important;
    min-width: 1px !important;
    max-width: 1px !important;
}

</style>